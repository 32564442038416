import { StateCreator } from "zustand";
import { iGlobalData } from "../Helper/interfaces";
import BigNumber from "./BigNumber";

const CreateGlobalData : StateCreator<iGlobalData> = (set)=>({
    timeLine:{
       
    },
    increaseRatio:1.15,
    totalMoney:new BigNumber(),
    time:0,
    syncDelay:30,
    csrfToken:"",
    lastCsrfRefresh:0,
    url:"",
    purchases:[],
    showLog: false,version:0,
    capsuleSync:true,
    slotLevel:10,
    slotMaxCapsules:3,
    timeToMoneyConversion:1,
    fullBoostMinValue:0,
    fullStarLength:0,
    requiredLevelForStar:0,
    branch_fruit_generation_duration:"",
    client_code:-1,
    step:0,
    client_type:'',contractRefreshPrice:0,
});

export const creatureLevelLimit= 2;
export const pageBaseAddress=window.location.host;
export default CreateGlobalData;